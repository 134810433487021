import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rename-correction"
export default class extends Controller {

  onIconClick(event) {
    const name = document.querySelector("#essay_suggester_name");
    name.focus();
    var val = name.value;
    name.value = '';
    name.value = val;
  }

  onBlur(event) {
    const name = document.querySelector("#essay_suggester_name");
    name.addEventListener('blur', (event) => {
      document.querySelector("#rename-correction-form").submit();
    });
  }

}
