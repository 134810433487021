import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["latestMessage"]

  connect() {
    // console.log(this.latestMessageTarget);
    // this.latestMessageTarget.scrollIntoView({block: 'nearest'});
  }
}

