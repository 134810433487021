import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="term-form"
export default class extends Controller {
  static targets = [
    "subjectField",
    "marksFields",
    "termGoalField",
    "termSelect",
    "termGoalInput",
  ];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    const term = this.termSelectTarget.value;

    if (term === "4") {
      this.termGoalInputTarget.setAttribute("required", "required");

      this.subjectFieldTarget.classList.add("hidden");
      this.marksFieldsTarget.classList.add("hidden");
      this.termGoalFieldTarget.classList.remove("hidden");
    } else {
      this.termGoalInputTarget.removeAttribute("required");

      this.subjectFieldTarget.classList.remove("hidden");
      this.marksFieldsTarget.classList.remove("hidden");
      this.termGoalFieldTarget.classList.add("hidden");
    }
  }
}
