import { Controller } from "@hotwired/stimulus";

const essayTypeMapper = {
  "Text Analysis": "Analyses a single literary text",
  "Comparative Essay": "Analyses and compares multiple literary texts",
  "Persuasive Essay": "Argues a point of view on an issue",
};

// Connects to data-controller="corrections"
export default class extends Controller {
  connect() {
    let essayTypeHelper = document.getElementById("essay_type_helper");
    if(this.element.value === ''){
      essayTypeHelper.textContent = ''
    }
    else{
      essayTypeHelper.textContent = `${this.element.value}: ${
        essayTypeMapper[this.element.value]
      }`;  
    }
  }

  onChange(e) {
    let essayTypeHelper = document.getElementById("essay_type_helper");
    if(this.element.value === ''){
      essayTypeHelper.textContent = ''
    }
    else{
      essayTypeHelper.textContent = `${this.element.value}: ${
        essayTypeMapper[this.element.value]
      }`;  
    }
  }
}
