import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="validations"
export default class extends Controller {

  onChange(){
    let ai_paragraph = document.querySelector("#your_paragraph")
    let words_length = ai_paragraph.value.split(/\s+/).length
    if(words_length < 80){
      ai_paragraph.setCustomValidity('Please ensure you have got a minimum of 80 words in your response')
    }
    else if(words_length > 400){
      ai_paragraph.setCustomValidity(`Please limit your paragraph to 400 words. It is currently at ${words_length} words`)
    }
    else{
      ai_paragraph.setCustomValidity('')
    }
  }

  validateComment() {
    let comment = this.data.scope.element;
    let words_length = comment.value.split(/\s+/).length

    if (comment.value.length < 50) {
      comment.setCustomValidity("Please provide some more detail to help us improve.")
    }
    else if(words_length > 400) {
      comment.setCustomValidity(`Please limit your paragraph to 400 words. It is currently at ${words_length} words`)
    }
    else{
      comment.setCustomValidity('')
    }
  }
}
