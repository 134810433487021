import { Flatpickr } from 'flatpickr'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["date"]
    static values = {
    enableTime: Boolean
  }

  connect() {
    let that = this;
    flatpickr(this.dateTarget, {
      minDate: "today",
      altInput: true,
      enableTime: this.enableTimeValue,
      minuteIncrement: 20,
      onChange: function(selectedDates, dateStr, instance) {
        that.dateTarget.form.requestSubmit();
      }
    });
  }
}
