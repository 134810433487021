import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="overlay"
export default class extends Controller {
  static targets = ["component"]

  close(){
    this.componentTarget.innerHTML = "";
    this.componentTarget.removeAttribute("src");
  }
}
