import "form-request-submit-polyfill"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.body.addEventListener('keydown', this.submit_on_ctrl_enter)
  }

  disconnect() {
    document.body.removeEventListener('keydown', this.submit_on_ctrl_enter)
  }

  submit_on_ctrl_enter(e) {
    if(!(e.keyCode == 13 && (e.metaKey || e.ctrlKey))) return;

    var target = e.target;
    if(target.form) {
      target.form.requestSubmit();
    }
  }
}
