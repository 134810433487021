import consumer from "channels/consumer";

let resetFunc;
let timerOnline = 0;
let timerKeyDown = 0;

consumer.subscriptions.create("AppearanceChannel", {
  initialized() {},
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected");
    resetFunc = () => this.resetTimer(this.uninstall);
    this.install();
    window.addEventListener("turbo:load", () => this.resetTimer());
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log("Disconnected");
    this.uninstall();
  },
  rejected() {
    // console.log("Rejected");
    this.uninstall();
  },
  received(data) {
    // Called when there's incoming data on the websocket for this channel
  },
  online() {
    // console.log('user logged in', this.isUserLoggedIn());
    if (this.isUserLoggedIn()) {
      console.log("online");
      this.perform("online");
    }
  },
  uninstall() {
    // console.log("Uninstall");
    clearTimeout(timerOnline);
  },
  install() {
    // console.log("Install");
    window.removeEventListener("load", resetFunc);
    window.removeEventListener("DOMContentLoaded", resetFunc);
    window.removeEventListener("keydown", this.handelKeyDown);

    window.addEventListener("load", resetFunc);
    window.addEventListener("DOMContentLoaded", resetFunc);
    window.addEventListener("keydown", this.handelKeyDown);
    this.resetTimer();
  },
  handelKeyDown() {
    clearTimeout(timerKeyDown);
    timerKeyDown = setTimeout(() => {
      resetFunc();
    }, 600);
  },
  resetTimer() {
    // console.log("Reset Timer");
    this.uninstall();

    timerOnline = setTimeout(this.online.bind(this), 100);
  },
  isUserLoggedIn() {
    return window.location.pathname != "/users/sign_in";
  },
});
