import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // specifically used to update files for cal_events
  static targets = ["newFileInput"];

  connect() {
    this.initializeRemoveButtons();
  }

  initializeRemoveButtons() {
    this.existingFilesTarget &&
      this.existingFilesTarget
        .querySelectorAll(".remove-file-btn")
        .forEach((button) => {
          button.addEventListener(
            "click",
            this.handleRemoveExistingFile.bind(this)
          );
        });
  }

  handleRemoveExistingFile(event) {
    const button = event.target;
    const fileId = button.getAttribute("data-file-id");

    // Remove the file element from the DOM
    const fileElement = button.closest(".file-item");
    fileElement.remove();

    // Add a hidden input to mark the file for removal
    const removedFileInput = document.createElement("input");
    removedFileInput.type = "hidden";
    removedFileInput.name = "removed_files[]";
    removedFileInput.value = fileId;
    document.getElementById("fileForm").appendChild(removedFileInput);
  }

  validateForm() {
    const allFilesLength =
      this.existingFilesTarget.children.length +
      this.newFileInputTarget.files.length;
    if (allFilesLength === 0) {
      this.newFileInputTarget.setCustomValidity(
        "Please choose atleast one file."
      );
    } else {
      this.newFileInputTarget.setCustomValidity("");
    }
  }
}
