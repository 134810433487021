import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="audio-completion"
export default class extends Controller {

  connect() { // used to show complete bth when audio is completed
    let audioElement = this.data.scope.element;
    audioElement.addEventListener("ended", (event) => {
      document.querySelector('.arcicle-complete-button').classList.remove('hidden');
    });
  }

}
