import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="correction-ratings"

export default class extends Controller {

  connect() {
    const radioButtons = document.querySelectorAll('input[name="correction_rating[rating]"]');
    radioButtons.forEach((radioButton) => {
      radioButton.addEventListener("change", this.validateComment.bind(this));
    });
  }

  // for correction rating, only if rating is 3 or less
  validateComment(event) { 
    let comment = this.data.scope.element;
    this.commentValidator(comment);
  }

  commentValidator(comment){
    let rating = document.querySelector('input[name="correction_rating[rating]"]:checked').value;
    if(parseInt(rating) <= 3){
      let words_length = comment.value.split(/\s+/).length;
      if (comment.value.length < 50) {
        comment.setCustomValidity("Please provide some more detail to help us improve.");
      }
      else if(words_length > 400) {
        comment.setCustomValidity(`Please limit your paragraph to 400 words. It is currently at ${words_length} words`);
      }
      else{
        comment.setCustomValidity('');
      }  
    }
    else {
      comment.setCustomValidity('');
    }
  }
}
