import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["videoPlayer"];
  connect() {
    videojs(this._videoPlayer, {
      controls: true,
      autoplay: false,
      inactivityTimeout: 0,
      fluid: true,
      preload: "auto",
    });
  }

  disconnect() {
    videojs(this._videoPlayer).dispose();
  }

  get _videoPlayer() {
    return this.videoPlayerTarget.getElementsByTagName("video")[0];
  }
}
