import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="rating-validator"
export default class extends Controller {
  connect() {
    const radioButtons = document.querySelectorAll(
      'input[name="student_meeting_feedback[rating]"]'
    );
    radioButtons.forEach((radioButton) => {
      radioButton.addEventListener(
        "change",
        this.handleRatingChange.bind(this)
      );
    });

    const radioButtons2 = document.querySelectorAll(
      'input[name="student_meeting_feedback[is_game_changer]"]'
    );
    radioButtons2.forEach((radioButton) => {
      radioButton.addEventListener(
        "change",
        this.handleGameChangerChange.bind(this)
      );
    });

    this.element.addEventListener("submit", this.validateRating.bind(this));
  }

  // for student meeting feedack, only if rating is 3 or less
  validateComment() {
    let comment = this.data.scope.element;
    this.commentValidator(comment);
  }

  //helper methods
  handleRatingChange(event) {
    let inputWrapper = document.querySelector("#gamechanger-radio");
    let input = document.querySelectorAll(
      'input[name="student_meeting_feedback[is_game_changer]"]'
    )[0];
    if (event.target.value === "5") {
      inputWrapper.classList.remove("hidden");
      input.setAttribute("required", "");
    } else {
      inputWrapper.classList.add("hidden");
      input.removeAttribute("required");
    }
    let comment = document.querySelector("#student_meeting_feedback_comment");
    this.commentValidator(comment);
  }

  handleGameChangerChange(event) {
    let inputWrapper = document.querySelector("#gamechanger-field");
    let input = document.querySelector(
      "#student_meeting_feedback_game_changer_reason"
    );
    if (event.target.value === "0") {
      inputWrapper.classList.add("hidden");
      input.removeAttribute("required");
    } else {
      inputWrapper.classList.remove("hidden");
      input.setAttribute("required", "");
    }
  }

  commentValidator(comment) {
    let ratingInput = document.querySelector(
      'input[name="student_meeting_feedback[rating]"]:checked'
    );
    let rating = ratingInput ? ratingInput.value : 0;
    if (parseInt(rating) <= 3) {
      let words_length = comment.value.split(/\s+/).length;
      if (comment.value.length < 50) {
        comment.setCustomValidity(
          "Please provide some more detail to help us improve."
        );
      } else if (words_length > 400) {
        comment.setCustomValidity(
          `Please limit your paragraph to 400 words. It is currently at ${words_length} words`
        );
      } else {
        comment.setCustomValidity("");
      }
    } else {
      comment.setCustomValidity("");
    }
  }

  validateRating() {
    const ratingErrorDiv = document.querySelector(
      "#student-meeting-feedback-error"
    );
    const selectedRating = document.querySelector(
      'input[name="student_meeting_feedback[rating]"]:checked'
    );

    if (!selectedRating) {
      ratingErrorDiv.textContent = "Rating can't be blank";
    } else {
      ratingErrorDiv.textContent = "";
    }
  }
}
