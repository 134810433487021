import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["picker", "button", "emojiContainer", "input"];

  connect() {
    this.open = false;
    this.value = null;
  }

  togglePicker() {
    this.open = !this.open;
    this.pickerTarget.classList.toggle("hidden", !this.open);
  }

  setValue(event) {
    event.preventDefault();
    const emoji = event.currentTarget.dataset.emoji;
    this.value = emoji;
    this.open = false;
    this.pickerTarget.classList.add("hidden");

    this.inputTarget.value = emoji;

    // Get the CSRF token from the meta tag
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    // Create a hidden input element for the CSRF token
    const csrfInput = document.createElement("input");
    csrfInput.type = "hidden";
    csrfInput.name = "authenticity_token";
    csrfInput.value = csrfToken;

    // Append the CSRF token to the form
    this.inputTarget.form.appendChild(csrfInput);

    // Submit the form
    this.inputTarget.form.submit();
  }
}
